import { FunctionComponent, SVGProps, useState } from 'react';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { useUser } from '@/hooks/useUser';
import { capitalize, sanitizeUsername } from '@/utils/strings';
import FleetsIcon from '@/components/ui/icons/FleetsIcon';
import AiTrainersIcon from '@/components/ui/icons/AiTrainersIcon';
import ScoutIcon from '@/components/ui/icons/ScoutIcon';
import NetworkIcon from '@/components/ui/icons/NetworkIcon';
import ChevronDownIcon from '@/components/ui/icons/ChevronIcon';
import DiscordIcon from '@/components/ui/icons/DiscordIcon';
import XIcon from '@/components/ui/icons/XIcon';
import LoginIcon from '@/components/ui/icons/LoginIcon';
import CloseIcon from '@/components/ui/icons/CloseIcon';
import HamburgerIcon from '@/components/ui/icons/HamburgerIcon';
import SettingsIcon from '@/components/ui/icons/SettingsIcon';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shadcn/select';
import MapIcon from '../icons/MapIcon';
import UploadIcon from '../icons/UploadIcon';
import UserLocationDisabledIcon from '../icons/UserLocationDisabledIcon';
import ConsoleIcon from '../icons/ConsoleIcon';
import InfoIcon from '../icons/InfoIcon';
import SearchIcon from '../icons/SearchIcon';
import ChevronRightIcon from '../icons/ChevronRightIcon';

const DEFAULT_USER = 'delicate-silver-kite';
const DEFAULT_FRAME_ID = '66905002de2b4e4a7d63bfd2';
const DEFAULT_DETECTION_ID = '30';

interface INavSection {
  label: string;
  subItems: INavItem[];
  isMobileOnly?: boolean;
}

interface INavItem {
  label: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  subItems?: INavItem[];
  url?: string;
  openInNewTab?: boolean;
}

const socialMediaLinks = [
  { label: 'X', icon: XIcon, url: 'https://x.com/Hivemapper' },
  {
    label: 'Discord',
    icon: DiscordIcon,
    url: 'https://discord.com/invite/FRWMKyy5v2',
  },
];

export default function AdminNav() {
  const { pathname } = useRouter();
  const { user, updateCurrentOrganization } = useUser();
  const { username } = user || {};

  const navItems: INavSection[] = [
    {
      label: 'Admin',
      subItems: [
        {
          label: 'Users',
          icon: FleetsIcon,
          subItems: [
            {
              label: 'Users List',
              url: '/admin/userInfo',
            },
            {
              label: 'App Targeting',
              url: '/admin/appTargeting',
            },
          ],
        },
        {
          label: 'Credits',
          icon: ConsoleIcon,
          subItems: [
            {
              label: 'Map Credit Purchases',
              url: '/admin/map-credits/purchases',
            },
            {
              label: 'Map Credits Provision',
              url: '/admin/map-credits/provision',
            },
            {
              label: 'Map Credits Consumption',
              url: '/admin/map-credits/consumption',
            },
          ],
        },
        {
          label: 'Drive Quality',
          icon: ScoutIcon,
          subItems: [
            {
              label: 'Image Guard',
              url: `/admin/image-guard/${DEFAULT_USER}`,
            },
            {
              label: 'Mount Ratings',
              url: '/api/admin/mountratings',
            },
            {
              label: 'User Mount Quality',
              url: '/api/admin/auditreviewedmounts/dynamic-carnelian-brewer/2024-05-15',
            },
            {
              label: 'User Audit GNSS',
              url: '/admin/auditgnss/663195da183ecdeb1a68ee2c',
            },
            {
              label: 'Dashcam Frame',
              url: `/admin/frame/${DEFAULT_FRAME_ID}/${DEFAULT_DETECTION_ID}`,
            },
          ],
        },
        {
          label: 'Collections',
          icon: SearchIcon,
          subItems: [
            {
              label: 'Regions',
              url: '/admin/regions',
            },
            {
              label: 'Object Detections',
              url: '/admin/objectdetections',
            },
            {
              label: 'Landmarks',
              url: '/admin/landmarks',
            },
            {
              label: 'MapFeatureCandidates',
              url: '/admin/mapfeaturecandidates',
            },
            {
              label: 'Map Features',
              url: '/admin/mapfeatures',
            },
            {
              label: 'Detection Areas',
              url: '/admin/detectionareas',
            },
            {
              label: 'Construction Data Sample',
              url: '/admin/constructiondata',
            },
          ],
        },
        {
          label: 'AI Trainers',
          icon: AiTrainersIcon,
          subItems: [
            {
              label: 'Dashboard',
              url: '/admin/dashboard',
            },
            {
              label: 'Create/List Trainers',
              url: '/admin/trainers',
            },
          ],
        },
        {
          label: 'User Anti-Spam',
          icon: AiTrainersIcon,
          subItems: [
            {
              label: 'User Leaderboards',
              url: '/admin/trainer-leaderboards',
            },
            {
              label: 'User Benchmark Responses',
              url: `/admin/benchmarkResponsesForUser`,
            },
            {
              label: 'User Benchmark Response Stats',
              url: `/admin/benchmarkResponseStats`,
            },
            {
              label: 'User Reviews',
              url: `/admin/reviewsForUser`,
            },
          ],
        },
        {
          label: 'Tool Anti-Spam',
          icon: AiTrainersIcon,
          subItems: [
            {
              label: 'Tool Benchmarks',
              url: '/admin/benchmarksForTool',
            },
            {
              label: 'Tool Reviews',
              url: `/admin/reviewsForTool`,
            },
            {
              label: 'Tool AdminReviews',
              url: `/admin/adminReviewsForTool`,
            },
            {
              label: 'Tool Outcomes',
              url: `/admin/outcomesForTool`,
            },
          ],
        },
        {
          label: 'Model Quality',
          icon: NetworkIcon,
          subItems: [
            {
              label: 'Landmark Label Confusion Matrix',
              url: '/api/landmarks/labelClassificationConfusionMatrix',
            },
            {
              label: 'Landmark Subclass Confirmed Stats',
              url: '/api/landmarks/subclassClassificationStats',
            },
          ],
        },
        {
          label: 'Pipeline',
          icon: ChevronRightIcon,
          subItems: [
            {
              label: 'Landmark Auditor',
              url: '/admin/landmarkaudits',
            },
            {
              label: 'Linters',
              url: '/admin/linters',
            },
            {
              label: 'Lint Failed MapFeatures (Valve)',
              url: '/admin/valve',
            },
            {
              label: 'Taxonomy',
              url: '/admin/taxonomy',
            },
            {
              label: 'Recon Jobs Stats',
              url: '/admin/reconjobstats',
            },
          ],
        },
        {
          label: 'Maps',
          icon: MapIcon,
          subItems: [
            {
              label: 'Explorer Tiles',
              url: '/admin/tiles',
            },
            {
              label: 'God Map',
              url: '/admin/godmode',
            },
            {
              label: 'Roadwork Map',
              url: '/admin/roadwork',
            },
            {
              label: 'Direction Hexes',
              url: '/admin/directions',
            },
            {
              label: 'PMTiles Viewer',
              url: '/admin/pmtiles-viewer',
            },
          ],
        },
        {
          label: 'Upload Tools',
          icon: UploadIcon,
          subItems: [
            {
              label: 'Upload Burst',
              url: '/admin/upload/burst',
            },
            {
              label: 'Upload Segment Burst',
              url: '/admin/upload/segmentBurst',
            },
            {
              label: 'Upload Regions',
              url: '/admin/upload/regions',
            },
          ],
        },
        {
          label: 'Data Redaction',
          url: '/admin/dataredaction',
          icon: UserLocationDisabledIcon,
        },
      ],
    },
  ];

  const isUrlActive = (url: string, path: string): boolean => {
    return url === path || path.includes(url);
  };

  const currentOrganizationName = user?.organizations?.find(
    org => org._id === user.currentOrganization,
  )?.name;

  const getInitialOpenSubItem = (path: string): string | null => {
    for (const section of navItems) {
      for (const item of section.subItems) {
        if (item.subItems) {
          for (const subItem of item.subItems) {
            if (isUrlActive(subItem.url ?? subItem.label, path)) {
              return item.label;
            }
          }
        }
      }
    }
    return null;
  };

  const [openSubItem, setOpenSubItem] = useState<string | null>(
    getInitialOpenSubItem(pathname),
  );
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleSubItem = (label: string) => {
    setOpenSubItem(prev => (prev === label ? null : label));
  };

  return (
    <>
      <div
        data-open={isNavOpen}
        className="no-scrollbar fixed top-14 z-50 flex size-full shrink-0 flex-col overflow-scroll bg-neutral-900 pb-40 text-neutral-0 data-[open=false]:hidden md:top-12 md:h-full md:max-w-[232px] md:pb-36 md:data-[open=false]:block">
        <nav className="flex-1">
          {navItems.map(section => (
            <div
              key={section.label}
              data-mobile={section.isMobileOnly}
              className="group/navSection">
              <div className="block px-4 py-2 leading-none md:p-4 md:group-data-[mobile=true]/navSection:hidden">
                <span className="text-[10px] font-bold uppercase leading-none tracking-[2px] text-harvestGoldWarning-400 md:text-xs md:tracking-[0.15rem]">
                  {section.label}
                </span>
              </div>
              <ul>
                {section.subItems.map(item => {
                  const Icon = item.icon;
                  const isSelected = openSubItem === item.label;
                  const isActive =
                    isUrlActive(item.url || item.label, pathname) ||
                    item.subItems?.some(subItem =>
                      isUrlActive(subItem.url ?? subItem.label, pathname),
                    );
                  return (
                    <div
                      key={item.label}
                      data-active={isActive}
                      className="group/navItem"
                      data-selected={isSelected}>
                      {item.subItems ? (
                        <>
                          <button
                            onClick={() => toggleSubItem(item.label)}
                            className="flex w-full items-center px-4 py-2 text-neutral-600 transition-colors hover:text-neutral-0 group-data-[active=true]/navItem:text-neutral-0 group-data-[selected=true]/navItem:text-neutral-0 md:px-6 md:group-data-[mobile=true]/navSection:hidden">
                            {Icon && (
                              <Icon className="mr-4 hidden size-6 fill-current transition-colors md:block" />
                            )}
                            <span className="transition-colors">
                              {item.label}
                            </span>
                            <ChevronDownIcon className="ml-auto fill-current transition-all group-data-[selected=true]/navItem:rotate-180" />
                          </button>
                          {openSubItem === item.label && (
                            <ul>
                              {item.subItems.map(subItem => {
                                const isSubItemActive = isUrlActive(
                                  subItem.url || subItem.label,
                                  pathname,
                                );

                                return (
                                  <li
                                    key={subItem.label}
                                    className="group/navSubItem"
                                    data-active={isSubItemActive}>
                                    <Link
                                      href={subItem.url || '#'}
                                      target={
                                        subItem.openInNewTab
                                          ? '_blank'
                                          : '_self'
                                      }
                                      className="flex w-full items-center px-6 py-2 text-neutral-600 transition-colors hover:text-neutral-0 group-data-[active=true]/navSubItem:bg-azure-700 group-data-[active=true]/navSubItem:text-neutral-0">
                                      <span className="md:ml-8">
                                        {subItem.label}
                                      </span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </>
                      ) : (
                        <li>
                          <Link
                            href={item.url || '#'}
                            target={item.openInNewTab ? '_blank' : '_self'}
                            className="flex items-center px-4 py-2 text-neutral-600 transition-colors hover:text-neutral-0 group-data-[active=true]/navItem:bg-azure-700 group-data-[active=true]/navItem:text-neutral-0 md:px-6 md:group-data-[mobile=true]/navSection:hidden">
                            {Icon && (
                              <Icon className="mr-4 hidden size-6 fill-current transition-colors md:block" />
                            )}
                            <span className="transition-colors">
                              {item.label}
                            </span>
                          </Link>
                        </li>
                      )}
                    </div>
                  );
                })}
              </ul>
            </div>
          ))}
          <div className="block md:hidden">
            <ul className="flex gap-6 p-4">
              {socialMediaLinks.map(link => {
                const Icon = link.icon;
                return (
                  <li key={link.label}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" flex items-center text-neutral-600 hover:text-neutral-0">
                      <Icon className="size-6 fill-current transition-colors" />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
        <div className="fixed bottom-0 flex h-[92px] w-full items-center bg-neutral-1000 font-bold text-neutral-0 md:max-w-[232px]">
          {username ? (
            <div className="flex size-full items-center justify-start gap-3 fill-current p-4 ">
              <div className="flex w-full flex-col gap-2">
                <div className="flex items-center justify-between">
                  <span className="w-[160px] truncate font-normal leading-none">
                    {sanitizeUsername(username)}
                  </span>
                  <Link href="/account">
                    <SettingsIcon className="size-6 fill-neutral-600" />
                  </Link>
                </div>
                {user?.organizations &&
                  user?.organizations.length > 1 &&
                  currentOrganizationName && (
                    <div className="w-full">
                      <Select
                        onValueChange={value => {
                          updateCurrentOrganization(value);
                          Router.reload();
                        }}>
                        <SelectTrigger className="h-7 bg-neutral-900 text-xs">
                          <SelectValue placeholder={currentOrganizationName} />
                        </SelectTrigger>
                        <SelectContent className="bg-neutral-900 text-xs">
                          {user?.organizations.map(org => (
                            <SelectItem key={org._id} value={org._id}>
                              {capitalize(org.name)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <Link href="/login">
              <button className="flex size-full items-center justify-start gap-3 p-4">
                <LoginIcon className="size-6 fill-current" />
                <span>Login / Sign Up</span>
              </button>
            </Link>
          )}
        </div>
      </div>
      <button
        className="fixed right-4 top-4 z-50 block text-neutral-0 md:hidden"
        onClick={() => setIsNavOpen(prev => !prev)}>
        {isNavOpen ? (
          <CloseIcon className="size-6 fill-current p-1" />
        ) : (
          <HamburgerIcon className="size-6 fill-current" />
        )}
      </button>
    </>
  );
}
